import Image from 'next/image'
import { SocialLinks as SocialLinksInf } from 'src/packages/lagaat-domain';


const SocialLinks = ({socialLinks}: {socialLinks: SocialLinksInf}) => {
    
    return (
        <div className="flex gap-4 lg:gap-0">
            {socialLinks && Object.entries(socialLinks).map(([name, socialLink]) => {

                return (
                    <a target='_blank' rel="noreferrer" className="lg:flex lg:px-4" key={name} title={name} href={socialLink.link}>
                        <Image
                            src={socialLink.icon}
                            width={24}
                            height={24}
                            alt={name}
                        />
                    </a>
                )
            })}
        </div>
    )
}

export default SocialLinks