import React, { RefObject} from "react";
import dynamic from 'next/dynamic'
import Image from 'next/image'
import tw, { classnames, alignItems, padding } from 'tailwindcss-classnames'
import { FooterOption } from 'src/packages/lagaat-domain'
import BottomFooter from './BottomFooter'

import footerImg from '../../../public/footer.png'
import useScrollPosition from '../../lib/useScrollPostition'
import { H2 } from '../'

type FooterProps = FooterOption & {
    contactRef: RefObject<HTMLDivElement | null>
}

const Contact = dynamic(()=>import('../Contact/Contact'))
const SHOW_CONTACT_THRESHOLD = 2000

const Footer = ({contactRef, ...footerOpts}: FooterProps) => {
    
    const scrollPosition = useScrollPosition().top
    const showContactForm = scrollPosition && scrollPosition > SHOW_CONTACT_THRESHOLD
    
    return (
        <div className='mt-20 md:mt-36 bg-orange-400 '>
            <div className="relative flex h-96">
                <Image src={footerImg} alt='deco' layout="fill" objectFit="cover" />
                <div  
                    className="relative z-10 w-56 sm:w-72 md:w-80 bg-white m-auto lg:w-full" 
                    id="footer-contact"
                    style={{
                        maxWidth: "928px"
                    }}                    
                >
                    <div className="px-5 sm:px-6 md:px-7 py-4 sm:py-5 md:py-7">
                        <H2 className={classnames(alignItems("items-center"), padding("pb-4"))} title="יצירת קשר" />
                        <div ref={contactRef}>
                            { showContactForm && 
                                <Contact />
                            }
                        </div>

                    </div>
                </div>
            </div>
            <BottomFooter {...footerOpts} />
        </div>
    )
}

export default Footer