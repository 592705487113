import tw, { classnames, borderWidth, fontSize, lineHeight, padding, textColor, display, flexWrap, gap, fontWeight, justifyContent, alignItems, margin, width, alignContent, borderColor, borderOpacity, textAlign } from "tailwindcss-classnames"
import { FooterOption } from 'src/packages/lagaat-domain'

import SocialLinks from './SocialLinks'

import {pagePadding} from '../../lib/tailwind/styles'

type FooterItemProps =  {
    text?: string,
    className?: string
}

const itemDecoStyle = `last:border-l-0 ${classnames(borderWidth("sm:border-l"))}`

const FooterItem = ({text, className}: FooterItemProps) => {
    return (
        <>
        { text &&
            <span className={`${className} ${classnames(fontSize("text-lg"), lineHeight("leading-none"), padding("sm:px-4"))}`} dangerouslySetInnerHTML={{__html: text}} />
        }
        </>
    )
}

const BottomFooter = (footerOpts: FooterOption) => {
    const { address, copyrights, designCredit, devCredit, email, name, phone, socialLinks} = footerOpts
    return (
        <div className={`2xl:max-w-screen-xl1/2 ${classnames(pagePadding, textColor("text-white"), padding("py-8", "2xl:px-12"), display("flex"), flexWrap("flex-wrap"), gap("gap-4", "sm:gap-0"), fontWeight("font-medium"), justifyContent("justify-center", "2xl:justify-between"), alignItems("items-center"), margin("2xl:mx-auto"))}`}>
            <FooterItem text={name} className={itemDecoStyle} />
            <FooterItem text={address} className={itemDecoStyle} />
            <FooterItem text={phone} className={itemDecoStyle} />
            <FooterItem text={email} className={itemDecoStyle} />

            {socialLinks && 
                <div className="w-full lg:w-auto">
                    <div className={classnames(width("w-36", "sm:w-40", "lg:w-auto"), margin("mx-auto", "my-4", "sm:my-10", "lg:my-0"), display("flex"), alignContent("content-center"), justifyContent("justify-center"), padding("pt-5", "lg:pt-0", "pb-4", "lg:pb-0"), borderWidth("border-t", "border-b", "lg:border-b-0", "sm:border-t-0"), borderColor("border-white"), borderOpacity("border-opacity-80"))}>
                        <SocialLinks socialLinks={socialLinks} />

                    </div>
                </div>
            }

            <div className={classnames(fontWeight("font-light"), display("flex"), flexWrap("flex-wrap"), alignContent("content-center"), justifyContent("justify-center"), gap("gap-4"), margin("lg:mt-6", "2xl:mt-0"))}>
                <FooterItem text={copyrights} className={classnames(width("w-full", "lg:w-auto"), textAlign("text-center"))} />
                <FooterItem text={designCredit} />
                <FooterItem text={devCredit} />

            </div>

        </div>
    )
}

export default BottomFooter